




































import { Component, Prop, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'

@Component({
  name: 'Trend',
})
export default class Index extends VueBase {
  @Prop() vulTop!: any
  @Watch('vulTop', { immediate: true, deep: true })
  init() {
    this.total = 0
    this.vulTop &&
      this.vulTop.forEach((item: any) => {
        this.total += item.count
      })
  }
  total = 0
  async goPath(name: string, query: any) {
    this.$router.push({ name: name, query })
  }
  getName(num: any) {
    switch (num) {
      case 1:
        return 'one'
      case 2:
        return 'two'
      case 3:
        return 'three'
    }
  }
}
