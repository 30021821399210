























































import { Component, Prop, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'

@Component({
  name: 'Trend',
})
export default class Index extends VueBase {
  @Prop() vulns!: any
  @Watch('vulns', { immediate: true, deep: true })
  init() {
    this.obj = {
      高危漏洞: 0,
      中危漏洞: 0,
      低危漏洞: 0,
      提示信息: 0,
    }
    this.total = 0
    this.vulns &&
      this.vulns.forEach((item: any) => {
        this.obj[item.level__name_type] = item.level__name_type__count
        this.total += item.level__name_type__count
      })
  }

  async goPath(name: string, query: any) {
    this.$router.push({ name: name, query })
  }

  obj: any = {
    高危漏洞: 0,
    中危漏洞: 0,
    低危漏洞: 0,
    提示信息: 0,
  }
  total: any = 0

  getPercentage(num: any) {
    if (this.total) {
      return ((num / this.total) * 100).toFixed(2)
    } else {
      return '0.00'
    }
  }
}
