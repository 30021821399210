





































import { Component, Prop } from 'vue-property-decorator'
import VueBase from '@/VueBase'

@Component({
  name: 'ProjectChart',
})
export default class Index extends VueBase {
  @Prop() projectTop!: any
  @Prop() height: any
  getName(num: any) {
    switch (num) {
      case 1:
        return 'one'
      case 2:
        return 'two'
      case 3:
        return 'three'
    }
  }

  goProject(id: any) {
    this.$router.push({
      path: '/project/appDetail/' + id,
    })
  }
}
