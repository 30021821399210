




import { Component, Prop, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import * as echarts from 'echarts'

@Component({
  name: 'Trend',
})
export default class Index extends VueBase {
  public pngUrl = ''
  @Prop({ default: true }) showAnimation!: boolean

  @Prop({
    default: [[], [], []],
  })
  data!: any
  @Watch('data', { immediate: true, deep: true })
  onDataChanged() {
    const appObj = {}
    const scaObj = {}
    this.data[2] &&
      this.data[2].forEach((item: any) => {
        appObj[item] = { count: 0, detail: {} }
        scaObj[item] = { count: 0, detail: {} }
      })

    if (this.data[0] && this.data[0].length) {
      const obj = appObj
      this.data[0].forEach((item: any) => {
        console.log(obj, obj[item.day_label], item.day_label)
        if (!obj[item.day_label]) {
          obj[item.day_label] = {}
          obj[item.day_label].count = 0
          obj[item.day_label].detail = {}
        } else {
          obj[item.day_label].count += item.count
          obj[item.day_label].detail[item.level_id] = item.count
        }
      })
    }
    if (this.data[1] && this.data[1].length) {
      const obj = scaObj
      this.data[1].forEach((item: any) => {
        if (!obj[item.day_label]) {
          obj[item.day_label].count = 0
          obj[item.day_label].detail = {}
        }
        obj[item.day_label].count += item.count
        obj[item.day_label].detail[item.level_id] = item.count
      })
    }
    this.randerEchart(appObj, scaObj, this.data[2])
  }

  randerEchart(appObj: any, scaObj: any, X: any) {
    const colorMap = {
      1: '#E56363',
      2: '#F49E0B',
      3: '#2F90EA',
      5: '#ACB4C4',
    }
    const XData: any = X
    const YHData: any = []
    const YMData: any = []
    if (XData) {
      for (let i = 0; i < XData.length; i++) {
        YHData.push(appObj[XData[i]].count)
        YMData.push(scaObj[XData[i]].count)
      }
    }
    const option: any = {
      animation: this.showAnimation,

      tooltip: {
        trigger: 'axis',
        className: 'echart-tooltip-content',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
        formatter: (params: any) => {
          const obj = appObj[params[0].name].detail
          // const sca = scaObj[params[1].name].detail
          return `
          <div style="width:350px;">
            <div>
              <div style="font-size:12px"><span style="display:inline-block;width:10px;height:10px;background: #1A80F2;border-radius: 50%;"></span> 应用漏洞</div>
              <div style="display:flex;flex-wrap: wrap;padding:8px;background:#fff">
                <div style="padding-right:20px;width:50%;color:#959fb4;font-size:12px"><span style="display:inline-block;width:8px;height:8px;background: #E56363;border-radius: 50%;margin-right:8px;"></span>高危<span style="float:right;color:#38435A">${
                  obj[1] || 0
                }</span></div>
                <div style="padding-right:20px;width:50%;color:#959fb4;font-size:12px"><span style="display:inline-block;width:8px;height:8px;background: #F49E0B;border-radius: 50%;margin-right:8px;"></span>中危<span style="float:right;color:#38435A">${
                  obj[2] || 0
                }</span></div>
                <div style="padding-right:20px;width:50%;color:#959fb4;font-size:12px;margin-top:8px"><span style="display:inline-block;width:8px;height:8px;background: #2F90EA;border-radius: 50%;margin-right:8px;"></span>低危<span style="float:right;color:#38435A">${
                  obj[3] || 0
                }</span></div>
                <div style="padding-right:20px;width:50%;color:#959fb4;font-size:12px;margin-top:8px"><span style="display:inline-block;width:8px;height:8px;background: #ABB2C0;border-radius: 50%;margin-right:8px;"></span>提示<span style="float:right;color:#38435A">${
                  obj[5] || 0
                }</span></div>
              </div>
            </div>
          </div>
          `
        },
      },
      legend: false,
      // legend: {
      //   icon: 'rect',
      //   itemHeight: 4,
      //   itemWidth: 20,
      //   itemGap: 20,
      //   bottom: '0',
      //   data: ['应用漏洞'],
      // },
      grid: {
        left: '2%',
        right: '2%',
        bottom: '8%',
        top: '8%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          boundaryGap: false,
          data: XData,
          axisTick: {
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: '#262626',
            },
          },
          splitLine:false
        },
      ],
      yAxis: [
        {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#262626',
            },
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
            },
          },
        },
      ],
      series: [
        {
          type: 'line',
          layoutAnimation: this.showAnimation,
          markLine: {
            symbol: ['none', 'none'],
            silent: true,
            label: { show: false },
            itemStyle: {
              normal: {
                color: '#F2F3F5',
              },
            },
            data: [
              { xAxis: 0 },
              { xAxis: 1 },
              { xAxis: 2 },
              { xAxis: 3 },
              { xAxis: 4 },
              { xAxis: 5 },
              { xAxis: 6 },
            ],
          },
        },
        {
          name: '应用漏洞',
          type: 'line',
          symbol: 'circle',
          symbolSize: 0,
          smooth: true,
          color: ['rgba(15, 198, 194, 1)'],
          itemStyle: {
            borderColor: '#ffffff',
            borderType: 'solid',
            borderWidth: '1',
          },

          emphasis: {
            focus: 'series',
          },
          data: YHData,
        },
        // {
        //   name: '组件漏洞',
        //   type: 'line',
        //   symbol: 'circle',
        //   symbolSize: 0,
        //   smooth: true,
        //   color: ['rgba(26, 134, 238, 1)'],
        //   itemStyle: {
        //     borderColor: '#ffffff',
        //     borderType: 'solid',
        //     borderWidth: '1',
        //   },
        //   areaStyle: {
        //     color: new (echarts as any).graphic.LinearGradient(0, 0, 0, 1, [
        //       {
        //         offset: 0,
        //         color: 'rgba(26, 134, 238, 0.66)',
        //       },
        //       {
        //         offset: 1,
        //         color: 'rgba(255,255,255,0)',
        //       },
        //     ]),
        //   },
        //   emphasis: {
        //     focus: 'series',
        //   },
        //   data: YMData,
        // },
      ],
    }
    const dom = (document as any).getElementById('trend')
    if (!dom) {
      return
    }
    const myChart = echarts.init((document as any).getElementById('trend'))
    myChart.setOption(option)

    this.pngUrl = myChart.getDataURL()
  }
}
