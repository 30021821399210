









import { Component, Prop, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import * as echarts from 'echarts'

@Component({
  name: 'Type',
})
export default class Index extends VueBase {
  @Prop() data!: any
  @Prop() height!: number
  @Watch('data', { immediate: true, deep: true })
  onDataChanged() {
    this.randerEchart()
  }

  mounted() {
    this.randerEchart()
  }

  sortByCount(arr: any) {
    return arr.sort((a: any, b:any) => b.type_count - a.type_count);
  }

  private map: any = [
    {bg: '#FEE2E2', color: '#EF4444'},
    {bg: '#FEF3C7', color: '#F59E0B'},
    {bg: '#E0F2FE', color: '#0EA5E9'},
    {bg: '#E2E8F0', color: '#64748B'},
    {bg: '#E2E8F0', color: '#64748B'},
  ]
  randerEchart() {
    let total = 0
    const typeData: any = []
    const colorMap = {
      1: '#EF4444',
      2: '#F59E0B',
      3: '#0EA5E9',
      5: '#64748B',
    }
    let sortArr = this.sortByCount([...this.data]).slice(0, 5)
    sortArr.forEach((item: any) => {
      total += item.type_count
      typeData.push({ value: item.type_count, name: item.type_name, leval: item.type_level,
        percentage: item.type_total_percentage,
        itemStyle: {
          color: colorMap[item.type_level]
        }})
    })
    typeData.reverse()
    if (typeData.length < 5) {
      for (let index = typeData.length; index < 5; index++) {
        typeData.unshift({
          value: '', name: '', leval: ''
        })
      }
    }

    const option: any = {
      tooltip: {
        show: true,
        trigger: 'axis',
        axisPointer: {
            type: "shadow",
        },
        shadowStyle: {
          color: '#F2F3F5',
          width: '32',
          type: 'default'
        },
        padding: [6, 12],
        formatter: (params: any) => {
          let { data } = params[0]
          if (!data.name) {
            return ''
          }
          return `
            <div class="tooltip">
              <div style="display: flex;">
                <span class="dot" style="background: ${colorMap[data.leval]}"></span><div class="name">${data.name}</div>
              </div>
              <div class="item">
                <span class="label">
                  数量
                </span>
                <span class="value">
                  ${data.value}
                </span>
              </div>
              <div class="item">
                <span class="label">
                  占比
                </span>
                <span class="value">
                  ${(data.percentage*100).toFixed(2)}%
                </span>
              </div>
            </div>
          `
        },
      },
      grid: {
        left: 0,
        right: 1,
        bottom: 0,
        top: 0,
        containLabel: true,
      },
      xAxis: {
        show: true,
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#E5E7EB'
          }
        },
      },
      yAxis: [
        {
          type: "category",
          data: typeData.map((item: any)=> {
            if (item.name.length < 11) {
              return item.name
            } else {
              
              return item.name.slice(0, 10) + '...'
            }
          }),
          axisLabel: {
            show: true,
            margin: 12,
            textStyle: {
              color: '#4B5563'
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#E5E7EB'
            }
          },
          axisTick: {
            show: false,
          },
        },
      ],
      series: [
        {
          name: '类型分布',
          type: 'bar',
          barWidth: 12,
          data: typeData,
        },
      ],
    }
    const dom = (document as any).getElementById('type')
    if (!dom) {
      return
    }
    const myChart = echarts.init(dom)
    myChart.setOption(option)
  }
}
