








import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import * as echarts from 'echarts'

@Component({
  name: 'Distribution-round',
})
export default class Index extends VueBase {
  @Prop() data!: any
  @Prop() height!: number

  @Prop({ default: true }) showAnimation?: boolean

  @Watch('data', { immediate: true, deep: true })
  onDataChanged() {
    this.randerEchart()
  }

  public pngUrl = ''

  mounted() {
    this.randerEchart()
  }

  @Emit('onClick')
  private handleClick(data: any) {
    return data
  }

  private color: any = ['#c62d29', '#f1803a', '#8ceafb', '#4baec3', '#a4a4a4']
  private colorHover: any = {
    严重: '#c62d29',
    高危: '#f1803a',
    中危: '#8ceafb',
    低危: '#4baec3',
    提示: '#a4a4a4',
  }

  private randerEchart() {
    const data: any = []
    const selected = {}
    const sum = this.data.reduce((prev: any, { num }: any) => {
      return (prev += Number(num))
    }, 0)
    let sumStr = String(sum).split('')
    sumStr.length > 3 && sumStr.splice(-3, 0, ',')
    console.log(" ======== charts ==== ")
    console.log(this.data);
    this.data.forEach((item: any) => {
      data.push({
        value: item.num,
        p: ((item.num / sum) * 100).toFixed(2) + '%',
        id: item.level_id,
        name: item.level_name,
        itemStyle: {
          emphasis: {
            color: this.colorHover[item.level_name], // 设置第二个数据项高亮时的颜色
          },
        },
      })
      selected[item.level_name] = item.num > 0
    })
    const option: any = {
      animation: this.showAnimation,
      tooltip: {
        trigger: 'item',
        borderWidth: 0,
        padding: [6, 8],
        formatter: (params: any) => {
          let { data } = params
          // const colorMap = {
          //   高危: '#c62d29',
          //   中危: '#f1803a',
          //   低危: '#8ceafb',
          //   提示: '#4baec3',
          //   无风险: '#a4a4a4',
          // }
          const colorMap = {
            高危: '#f1803a',
            中危: '#8ceafb',
            低危: '#4baec3',
            提示: '#a4a4a4',
          }
          return `
            <div class="tooltip">
              <span class="dot" style="background: ${
                colorMap[data.name]
              }"></span><span class="name">${data.name}</span>
              <span class="value">
                ${data.value}
              </span>
            </div>
          `
        },
      },
      title: [
        {
          // text: '{c|' + '漏洞数量' + '}\n{a|' + sumStr.join('') + '}',
          x: 'center',
          y: '33%',
          textStyle: {
            rich: {
              a: {
                color: '#111827',
                fontSize: 24,
                fontWeight: 500,
                height: 32,
              },
              c: {
                fontSize: 14,
                fontWeight: 500,
                color: '#4B5563',
                padding: [4, 0],
              },
            },
          },
        },
      ],
      legend: {
        tooltip: {
          show: false,
        },
        // itemWidth: 8,
        // itemHeight: 8,
        bottom: -5,
        borderWidth: 0,
        itemGap: 24,
        // icon: 'circle',
        data: data.map((item: any) => item.name),
        selected: selected,
        selectedMode: !(sum === 0),
      },
      series: [
        {
          type: 'pie',
          radius: ['45%', '75%'],
          color: this.color,
          // itemStyle: {
          //   borderColor: '#fff',
          //   borderWidth: 2,
          // },
          label: {
            show: true,
            color: '#000',
            formatter: (params: any) => {
              let { data } = params
              return data.name + ' ' + data.p
              // return `{a|${data.p}a}`
            },
            // rich: {
            //   a: {
            //     color: '#fff',
            //     width:20,
            //     height:10,
            //     backgroundColor:'red'
            //   },
            // },
          },
          labelLine: {
            show: true,
          },
          data: [...data],
        },
      ],
    }
    const dom = (document as any).getElementById('distribution-round')
    if (!dom) {
      return
    }
    const myChart = echarts.init(dom)
    myChart.setOption(option)

    this.pngUrl = myChart.getDataURL()

    myChart.on('click', 'series.pie', (param) => {
      this.handleClick(param.data)
    })

    myChart.getZr().on('click', (e: any) => {
      if (!e.target) {
        this.handleClick({ id: -1 })
      }
    })
  }
}
