







































































































































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import Trend from './components/trend.vue'
import Scale from './components/scale.vue'
import Vuln from './components/vuln.vue'
import VulnType from './components/vulnType.vue'
import Project from './components/project.vue'
import Distribution from '../project/components/distribution.vue'
import DistributionRound from '../project/components/distribution-round.vue'
import TypeBar from '../project/components/typeBar.vue'
import { returnFloat1 } from '@/utils/utils'
@Component({
  name: 'Dashboard',
  components: {
    VulnType,
    Trend,
    Scale,
    Vuln,
    Project,
    TypeBar,
    Distribution,
    DistributionRound,
  },
})
export default class Dashboard extends VueBase {
  now = new Date()
  lastWeek = new Date(this.now.getTime() - 7 * 24 * 3600 * 1000)
  times = [this.lastWeek, this.now]
  type = '3'
  active = '7'
  data: any = {}

  async goPath(name: string, query: any) {
    this.$router.push({ name: name, query })
  }

  private searchDate:any = ''

  private levelMap: any = {
    高危漏洞: {
      level_id: 4,
      level_name: '高危',
    },
    中危漏洞: {
      level_id: 3,
      level_name: '中危',
    },
    低危漏洞: {
      level_id: 2,
      level_name: '低危',
    },
    提示信息: {
      level_id: 1,
      level_name: '提示',
    },
  }

  private mapName: any = {
    严重: '高危',
    高危: '中危',
    中危: '低危',
    低危: '提示',
    无风险: '无风险',
  }

  async getDashboard() {
    this.loadingStart()
    let timestamp_end = 0
    if (this.active === '1') {
      timestamp_end = Math.floor(this.times[1].getTime() / 1000) + 60 * 60 * 24
    } else {
      timestamp_end = Math.floor(this.times[1].getTime() / 1000)
    }
    const res = await this.services.project.dashboard({
      timestamp_begin: Math.floor(this.times[0].getTime() / 1000),
      timestamp_end: timestamp_end,

      type: this.type,
    })
    this.loadingDone()
    this.data = res.data
    let valueTotal = 0
    this.data.level_count = this.data.vul_group_by_level.map((item: any) => {
      valueTotal += item.level__name_type__count
      return {
        num: item.level__name_type__count,
        level_name: this.levelMap[item.level__name_type].level_name,
        level_id: this.levelMap[item.level__name_type].level_id,
      }
    })
    this.data.level_count.forEach((item: any) => {
      item.level_total_percentage = returnFloat1(item.num / valueTotal)
    })
    this.data.level_count.sort((a: any, b: any) => {
      return b.level_id - a.level_id
    })

    let typeTotal = 0
    this.data.type_summary = this.data.vul_type_top_n.map((item: any) => {
      typeTotal += item.count
      return {
        type_name: item.strategy__vul_name,
        type_count: item.count,
        type_level: item.strategy__level_id,
      }
    })
    this.data.type_summary.forEach((item: any) => {
      item.type_total_percentage = returnFloat1(item.type_count / typeTotal)
    })

    let scaTotal = 0
    this.data.sca_count_echart = this.data.sca_count_with_vul.map(
      (item: any) => {
        scaTotal += item.level__name_type__count
        return {
          num: item.level__name_type__count,
          // level_name: this.mapName[item.level__name_type],
          level_name: item.level__name_type,
          level_id: item.level,
        }
      }
    )

    this.data.sca_count_echart.forEach((item: any) => {
      item.level_total_percentage = returnFloat1(item.num / valueTotal)
    })
    this.data.sca_count_echart.sort((a: any, b: any) => {
      return b.level_id - a.level_id
    })
    this.data.project_vul_top_n = this.data.project_vul_top_n?.slice(0,5)
  }

  async changeTpye(e: any) {
    this.getDashboard()
  }

  async changeTime() {
    this.active = '1'
    this.getDashboard()
  }

  async changeActive() {
    // this.active = type
    const type = this.searchDate
    this.times = [
      new Date(this.now.getTime() - type * 24 * 3600 * 1000),
      this.now,
    ]
    this.getDashboard()
  }
  created() {
    this.getDashboard()
  }
}
